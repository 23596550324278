import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { Link } from 'gatsby';

export const AccountContainer = styled.div`
  background: #ffffff  url(${(props) => props?.$backgroundImage}) no-repeat top center;
  width: 100%;
  margin: -20px 0 0 0;
  @media (max-width: 1199px) {
    background-size: 1500px auto;
  }
  @media (max-width: 991px) {
    background: #ffffff url(${(props) => props?.$backgroundImageMediumDevice})
      no-repeat top center;
    margin: -200px 0 0 0;
  }

  @media (max-width: 767px) {
    background: #ffffff  url(${(props) => props?.$backgroundImageSmallDevice})
      no-repeat top center;
    margin-top: 0;
  }
`;

export const WrapperInnerDiv = styled.div`
  float: left;
  width: 80%;
  padding-top: 350px;
  text-align: ${(props) => (props.$isLeft ? 'right' : 'left')};
  @media (max-width: 991px) {
    padding-top: 300px;
  }
  @media (max-width: 767px) {
    float: none;
    text-align: center;
    width: 100%;
    padding: 85px 30px;
  }
`;

export const AnchorAccountType = styled.a`
  display: inline-block;
  text-align: center;
  padding: 20px 60px;
  text-decoration: none;
  margin: 0 auto;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-size: 70px;
  line-height: 70px;
  letter-spacing: -0.02em;
  color: #fff;
  cursor: pointer;
  background: ${(props) =>
    props?.$background ? props?.$background : '#083a81d9'};
  transition: all 0.15s ease-out;
  :hover {
    color: ${(props) => (props?.$hoverColor ? props?.$hoverColor : '083a81d9')};
  }
  @media (max-width: 1199px) {
    font-size: 60px;
    line-height: 60px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 767px) {
    font-size: 35px;
    line-height: 35px;
    padding: 25px;
  }
`;

export const AnchorTagInternal = styled(Link)`
  text-decoration: none;
`;
export const AnchorTag = styled.a`
  display: block;
  width: 100%;
  height: 710px;
  margin: 0 auto;
  @media (max-width: 1199px) {
    height: 590px;
    width: 960px;
  }
  @media (max-width: 991px) {
    height: 750px;
    width: 728px;
  }
  @media (max-width: 767px) {
    height: 640px;
    width: 100%;
  }
`;
export const InnerSpan = styled.span`
  display: block;
  margin-top: 10px;
  font-family: 'Gotham-Bold', arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #fff;
`;
